<app-header></app-header>

<main class="content">
  <!-- Start: Article Clean -->
  <div class="article-clean">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
          <!-- Start: Intro -->
          <div class="intro">
            <h1 class="text-center">Contactos</h1>
            <img class="img-fluid border rounded border-dark shadow-lg bounce animated" src="assets/img/pi_map.png">
          </div>
          <!-- End: Intro -->
          <!-- Start: Text -->
          <div class="text"></div>
          <!-- End: Text -->
        </div>
      </div>
    </div>
  </div>
  <!-- End: Article Clean -->
  <!-- Start: pi-contacts -->
  <div class="article-clean">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
          <!-- Start: Intro -->
          <div class="intro">
            <h1 class="text-center">Contactos</h1>
          </div>
          <!-- End: Intro -->
          <!-- Start: icon-text -->
          <div class="d-flex align-items-center" style="margin-bottom: 6px;">
            <i class="icon ion-location icon-text"></i>
            <span>Rua de Santa Marinha, 39, 4400-291 Vila Nova de Gaia, Portugal.<br></span>
          </div>
          <!-- End: icon-text -->
          <!-- Start: icon-text -->
          <div class="d-flex align-items-center" style="margin-bottom: 6px;">
            <i class="fa fa-phone icon-text"></i>
            <span>+351 225 089 801 (Chamada para a rede fixa Nacional)<br></span>
          </div>
          <!-- End: icon-text -->
          <!-- Start: icon-text -->
          <div class="d-flex align-items-center" style="margin-bottom: 6px;">
            <i class="fa fa-at icon-text"></i>
            <span>portoinf@sapo.pt<br></span>
          </div>
          <!-- End: icon-text -->
          <!-- Start: icon-text -->
          <div class="d-flex align-items-center" style="margin-bottom: 6px;">
            <i class="icon ion-ios-world-outline icon-text"></i>
            <span><a href="http://portoinf.com/">http://portoinf.com</a><br></span>
          </div>
          <!-- End: icon-text -->
        </div>
      </div>
    </div>
  </div>
  <!-- End: pi-contacts -->
  <main>
